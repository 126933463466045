*{
    text-align: center;
}
.c{
    width: 100%;
    height: 100vh;
    padding: 150px 100px;
    display: flex;
    position: relative;
    .c-one{
        flex: 1;
        padding-right: 30px;
        h1{
            color:#fff;
            font-size: 40px;
            span{
                font-size: 45px;
            }
            margin-bottom: 30px;
        }
        h3{
            font-weight: 300;
            font-size: 38px;
        }
        .c-follow{
            display:flex ;
            justify-content: space-between;
            position: absolute;
            bottom: 150px;
            left: 150px;
            width: 350px;
            h2{
                span{color: #fff;}
            }
            img{
                width: 30px;
                height:30px;
            }
        }
    }
    .c-two{
        flex: 1;
        padding: 100px 0;
        div{
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            span{
                color: #00ff04;
                font-size: 28px;
            }
            input,textarea{
                color: #f0f8ff;
                background: #000;
                border: #f00 solid 1.3px;
            }
            textarea{
                width: 200px;
                height: 100px;
            }
        }
        button{
            color:#f0f8ff ;
            border-color:#f00;
            background: #000;
            padding:15px 20px ;
            
        }
    }
}
@media (max-width:1200px) {
    .c{
        height: 100vh;
        .c-one{
            h1{
                font-size: 30px;
                span{
                    font-size: 35px;
                }
            }
            h3{
                font-size: 28px;
            }
            .c-follow{
                width: 300px;
                h2{
                    span{color: #fff;}
                }
                img{
                    width: 25px;
                    height:25px;
                }
            }
        }
        .c-two{
            div{
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                span{
                    color: #00ff04;
                    font-size: 20px;
                }
                input{
                    height: 23px;
                    width: 100px;
                }
                textarea{
                    width: 150px;
                    height: 75px;
                }
            }
            button{
                padding:10px 15px ;
                
            }

        }
    }
}
@media (max-width:850px) {
    .c{
        display: block;
        overflow: auto;
    .c-two{
        transform: translateY(100px);
    }
    }

}
@media(max-width:500px){
    .c{
        padding: 50px 20px 0;
        .c-one{
            .c-follow{
                position: relative;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
                margin-top: 20px;
                display: block;
                width: 250px;
                h2{
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                a{
                    img{
                       margin-right: 10px;
                    }
                }
            }
        }
        .c-two{
            margin-top: 20px;
            padding: 0;
            transform: translateY(0);
            div{
                display: block;
                span{
                    display: block;
                    margin-bottom: 7px;
                }
                input{
                    width: 200px;
                    height: 30px;
                }
                textarea{
                    width: 200px;
                    height: 100px;
                }
            }
        }
    }
}