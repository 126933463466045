
*{
    font-family: 'consolas';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color:#ff0;
    transition: 0.7s;
}
.home{
    width: 100%;
    height: 100vh;
    background: #000;
    display: flex;
    position: relative;
    overflow: hidden;
}
.one{
    
    position: relative;
    width: 50%;
    img{
        width: 300px;
        height: 300px;
        border-radius:50% ;
        // clip-path: polygon(50% 0, 100% 25%, 100% 75%,50% 100%,0 75%, 0 25%);
        position: absolute;
        top: 150px;
        left: 200px;
        // filter: grayscale(1) opacity(0.7) ;
    }
    .jobname{
        font-size: 30px;
        position: absolute;
        width: 400px;
        bottom:150px;
        cursor: pointer;
        left:70%;
       transform: translateX(100px);
       p{
           span{
               color:#fff;
           }
       }
    }
    .square{
        width: 200px;
        height: 150px;
        position: absolute;
        bottom: 0;
        left: 0;

        transform: scale(2) rotate(-51deg);
        border-radius: 3px;
        background: linear-gradient(to right,#fff, #ff0,#ff0);
    }
}
.two{
    justify-content:center;
    align-items: center;
    display: grid;
    position: relative;
    place-items: center;
    width: 50%;
    span{
        transform: translateY(-100px);
    }
    h3{
        font-size: 40px;
        font-weight: 350;
        margin-bottom: 17px;
    }   
    h2{
        font-weight: bold;
        font-size: 43px;
        margin-bottom: 12px;
        
    }
    h1{
        font-weight: 900;
        font-size:50px ;
        span{
            font-size: 65px;
            color: #fff;
        }
    }
}
@media (max-width:1200px){
    .square{
        display: none;
    }
    .one{
        img{
            width: 250px;
            height: 250px;
        }
    }
    .one{
        .jobname{
        font-size: 20px;       
        }
    }
    .two{
        h3{
            font-size: 30px;
        }
        h2{
            font-size: 33px;
        }
        h1{
            font-size: 40px;
        }
    }
}
@media (max-width:850px){
    body{
        overflow: auto;
    }
  .home{
      width: 100%;
    //   height: 200vh;
      display:block;
      padding:0 ;
      .one{
          width: 100%;
          height: 50vh;
          text-align: center;
          .jobname{
              display: none;
            }
            img{  
                width: 200px;
                height: 200px;
                top: 100px;
                position: relative;
                left:0;
                
                
            }
        }
        .two{
          height: 50vh;
          width: 100%;
        //   padding-top: 20;
          text-align: center;

          span{
              transform: translateY(0);
            h3{
                font-size: 20px;
            }
            h2{
                font-size: 23px;
            }
            h1{
                font-size: 30px;
            }    
        
        }
      }
  }
}
@media(max-width:500px){
    .home{
        .one{
            img{
                width: 150px;
                height: 150px;
            }
            .jobname{
                display: block;
                position: relative;
                width: 100%;
                font-size: 20px!important;
                bottom: 0;
                left: 0;
                transform: translateY(100px) translateX(0);
                text-align: center;
            }
        }
        .two{
            span{
                margin-top: 20px;
                h1{
                    font-size: 25px;
                    span{
                        font-size: 30px;
                    }
                }
            }
        }
    }
}