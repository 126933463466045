.load{
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .load-box-1,
    .load-box-2,
    .load-box-3{
        width: 20px;
        height: 70px;
        border-radius: 10px;
        margin-right: 20px;
        transform: rotate(-45deg);
    }
    .load-box-3{
        background: #00f;
        margin-right: 0;
        animation: loading-anim infinite 1s;
        animation-delay:1s;
    }
    .load-box-2{
        background: #0f0;
        animation: loading-anim infinite 1s;
        animation-delay:0.5s;
    }
    .load-box-1{
        background: #f00;
        animation: loading-anim infinite 1s;
        animation-delay:0;
    }
}

@keyframes loading-anim{
    0%,100%{
        height: 70px;
        
    }
    50%{
        height: 140px;
    }
}