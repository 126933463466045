*{
    transition: 1s;
}
.p{
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 100px 30px 0;
    // display: flex;
    overflow-y: scroll;
    .p-pws{
        transition-duration: 1s;
        align-items: center ;
        // justify-content: center;
        justify-content: space-around;
        display: flex;
        margin-bottom: 25px;
        img{
            margin-right: 30px;
            width: 250px;
            position: sticky;
            // transform: translateX(110px);
        }
     
        .p-pws-word{
            font-family: 'consolas';
            h1,h2{
                color: #fff;
                font: 780 50px italic 'consolas';
                margin: 7px 0;
                font-family: 'consolas';
            }
            a{
                text-decoration: none;
                height: 20px;
                width: 30px;
                background: #ff0;
                color: #000;
                font-weight: 650;
                padding: 7px 10px;
                font-size: 25px;
                font-family: 'consolas';
                
            }
        }
    }
}
@media(max-width:500px){
    .p{
        padding: 40px 20px 0;
        display: block;
        .p-pws{
            margin-top: 20px;
            display: block;
            position: relative;
            text-align: center;
            img{
                margin-right: 0; 
                width: 150px;
            }
        }
        .p-pws-word{
            h1,h2{
                color: #fff;
                margin: 7px 0;
                font-size: 17px!important;
                font-family: 'consolas';
            }
            h3{
                font-size: 18px!important;
                font-family: 'consolas';
            }
        }
    }

}