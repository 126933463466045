@keyframes animText {
    from{
        transform: translateY(-50px);
        opacity: 0.1;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

*{
    img,h1,p,h2,h3,h4,h5,h6,li,textarea, input{
        animation: animText 2s;
        animation-timing-function: inherit;
    }
}