.s{
    width: 100%;
    height: 100vh;
    background: #000;
    padding: 200px;
    display: flex;
    overflow: hidden;
    transition: 0.7s;
    .s-one{
        flex:1;
        img{
            width: 350px;
            border-radius: 10px;
        }
    }
    .s-two{
        flex:1;
        h2{
            font-style: italic ;
            font-size: 40px;
            margin-bottom: 10px;
        }
        h3{
            font-style: oblique;
            font-size: 30px;
            margin-bottom: 10px;
        }
        ul>li{
            text-align: start;
            display: block;
            font-size: 27px;
           transform: translateX(100px);
           span{
               color: #fff;
           }
        }
    }
}
@media (max-width:1200px) {
    .s{
        .s-one{
            img{
                width: 200px;
            }
        }
        .s-two{
            h2{
                font-size: 30px;
            }
            h3{
                font-size: 20px;
            }
            ul>li{
                font-size: 17px;
            }
        }
    }
    
}
@media(max-width:850px){
    
    .s{
        height: 100vh;
        overflow: hidden;
        display: block;
        padding: 100px 0 0 0;
        .s-one{
            img{
                width: 225px;
            }
        }
        .s-two{
            h2{
                font-size: 23px;
            }
            h3{
                font-size: 16px;
            }
            ul>li{
                width: 350px;
                text-align: center;
                font-size: 15px;
               span{
                   
                   color: #fff;
               }
            }
        }
    }

}
@media(max-width:500px){
    .s{
        .s-two{
            ul>li{
                padding: 0;
                width: 300px;
                text-align: start;
            }
        }
    }
}