
*{
    text-align: center;
    font-family: 'consolas';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color:#ff0;
    transition: 0.7s;
}
.a{
    width: 100%;
    height: 100vh;
    background: #000;
    text-align: center;
    position: relative;
    padding: 180px 100px 0 100px ;
    .a-title{
        color: #fff;
        margin-bottom: 20px;
        font-size: 50px;
        cursor: pointer;
        display: inline-block;
        text-shadow: rgba(255, 255, 255, 0.411) 15px -15px;
        &:hover{
            text-shadow: none;
        }
    }
    h3{
        // max-width: 700px;
        font-size: 37px;
        margin-bottom: 40px;
        span{
            color: #fff;
        }
    }
    p{color: #ff0460;}
    .prlanicons{
        text-align: center;
        width:80%;
        position: relative;
        padding-top: 50px ;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        img{
            width:50px;
           height: 50px; 
        }
    }
}
@media (max-width:1200px) {
    .a{
        .a-title{
            font-size: 37px;
        }
        h3{
            font-size: 27px;
        }
        .prlanicons{
            img{
               width:40px;
               height:40px; 
            }
            flex: 3;
        }
    }    
}
@media (max-width:850px) {
    .a{
        padding-top: 100px;
    }
}
@media(max-width:500px){
    .a{
        padding: 50px 15px 0;
        .a-title{
            font-size: 30px;
        
        }
        h3{
            font-size:25px ;
        }
    }
}