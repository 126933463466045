
@font-face {
    font-family: 'consolas';
    src: url(./consolas.ttf);
}
*{
    font-family: 'consolas';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color:#ff0;
    transition: 1s;
}

.nav{
    display: flex;
    position: fixed;
    z-index: 10;
    justify-content: space-between;
    top:0;
    right: 0;
    left: 0;
    background: rgba($color: #000, $alpha: 1);
    padding: 15px 70px;
    div{
        
        display: flex;
        position: relative;
        flex: 1;
        img{
            width: 50px;
        }
        span{
            font-size: 30px;
            margin-left: 20px;
            margin-top: 7px;
            color: #52b254;
            
            a{
                text-decoration: none;
                cursor:wait;
            }
            &:hover{
                text-shadow: #f00 15px -15px,
                #ff0 -15px -15px,
                #00f -15px 15px,
                #fff 15px 15px;
            }
        }
    }
    ul{
        display: flex;
        position: relative;
        justify-content: space-between;
        flex: 1;
        li{
            margin-top: 13px;
            list-style: none;
            a{
                text-decoration: none;
                color: #ff0;
            }
        }
    }
}
.sticy{
    color: #52b254;
    font-size: 50px;
    z-index: 10;
    position: absolute;
    bottom: 40px;
    left: 50;
    font-family: 'consolas';
    cursor: cell;
}
.menu-icons{
    display: none;
    img{
        width: 30px;
    }
    position: fixed;
    z-index: 100 ;
    right: 20px;
    top: 20px;
}
@media(max-width:950px){
    .menu-icons{
        display: block;
        #menu{
            display: block;
        }
        #unmenu{
            display: none;
        }
    }
    #navBar{
        position: fixed;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        background-color: #000000f0;
        display: none;
        // flex: none;
        place-content: center;
        li{
            margin-top: 20px;
            a{
            font-size: 30px;
           }
        }
    }
    .sticy{
        display: none;
    }
}
@media (max-width:1300px) {
    .nav{
        ul{display: block;
            display: flex;
            position: relative;
            justify-content: space-between;
            flex: 1;
            li{
                margin-top: 13px;
                list-style: none;
                a{
                    text-decoration: none;
                    color: #ff0;
                }
            }
        }
    }   
}
@media (max-width:500px) {
    .nav{
        padding: 5px 10px;
        div{
            img{
                width: 30px;
                height: 30px;
                // transform: translateY(10px);
            }
            span{
                font-size: 20px;
            }
        }
    }
    
}